import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="employee--index"
export default class extends Controller {
  connect() {

    $('.mapping_field').select2()

    console.log('employee Controller')

    // If a pushstate has previously happened and the back button is clicked, hide any modals.
    // Function to close the modal if it's open
    // var button = $('#my_button');
    // if (button.attr('aria-expanded') === 'true') {
    //   console.log('true he bhai');
    //   button.attr('title', null);
    // } else {
    //   console.log('false he bhai');
    //   button.attr('title', 'Click to Expand');
    // }

    $(".filter-btn").click(function(){
      $(".employee-team-member").toggleClass("order-enable");
      $(".employee-separation").toggleClass("order-enable");
      $(".employee").toggleClass("order-enable");
      $(".employee-termination").toggleClass("order-enable");
      $(".employee-my-status").toggleClass("order-enable");
      $(".employee-team-status").toggleClass("order-enable");
      $(".employee").toggleClass("order-enable");
      $(".organizer-invoice").toggleClass("order-enable");
      $(".organizer-customer").toggleClass("order-enable");
      $(".organizer-user").toggleClass("order-enable");
      $(".employee-referral").toggleClass("order-enable");
    });

    $(document).on('turbo:load', function() {
      $(".filter-btn").click(function(){
        $(".organizers-daily-status").toggleClass("order-enable");
        $(".employee").toggleClass("order-enable");
      });
    })

    validatorBasic()
    customSelect2('#employee_marital_status', true);
    customSelect2('#employee_gender', true);
    customSelect2('#employee_work_experience_year', true);
    customSelect2('#employee_work_experience_month', true);
    customSelect2('#employee_designation_id', true);
    customSelect2('#employee_oversight_lead_id', true);
    customSelect2('#employee_working_shift_id', true);
    customSelect2('#employee_designation_id', true);
    customSelect2('#mappings', true);
    customSelect2('#employee_department_i`d', true);
    customSelect2('#employee_grade_id', true);
    customSelect2('#employee_employment_type_id', true);
    customSelect2('#employee_country_code', true);
    $(document).on('click', '.swal-confirmation-submit', function (e) {
      e.preventDefault();

      const $btn = $(this);
      const $form = $btn.closest('form');

      if ($form.length && !$form.valid()) return;

      Swal.fire({
        icon: $btn.data('icon') || 'warning',
        html: $btn.data('icon') === 'warning2' 
          ? `<img src="https://img.icons8.com/?size=256&id=1lpBESDEGeuX&format=png" class="swal2-custom-icon"/>` +
            `<div class="swal2-html-container">${$btn.data('confirm-message') || 'Are you sure you want to reject the employee?'}</div>`
          : $btn.data('confirm-message') || 'Are you sure?',
        showCancelButton: true,
        confirmButtonColor: '#d11a2a',
        confirmButtonText: $btn.data('confirm-text') || 'OK',
        cancelButtonText: $btn.data('cancel-text') || 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) $form.length ? $form.submit() : window.location.href = $btn.attr('href');
      });
    });
    $(document).on('click', '.swal-delete', function(event) {
      console.log('in invoice comment ')
      var $link = $(this);
      event.preventDefault();

      Swal.fire({
        icon: 'warning2',
        html: '<img src="https://img.icons8.com/?size=256&id=1lpBESDEGeuX&format=png" class="swal2-custom-icon" /><h2 class="swal2-title swal2-html-container">Are you sure you want to delete this comment??</h2>',
        showCancelButton: true,
        confirmButtonColor: '#d11a2a',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Delete',
        customClass: 'delete-popup',
      }).then(function(result) {
        if (result.isConfirmed) {
          $.ajax({
            url: $link.attr('href'),
            type: 'DELETE',
            headers: {
              'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
              $link.closest('.comment-row').remove();
            },
            error: function(xhr, status, error) {
              console.error('Delete failed:', error);
            }
          });
        }
      });
    });

    var section = $('#open-section').attr('section')
    var flag = true
    $('.accordion-employee-collapse').each(function() {
      $(this).removeClass( "show" )
      if ($(this).attr('id') == section) {
        $(this).addClass("show")
        flag = false
      }
    });

    // employee aside page change employee image in employee side
    $('#edit-button-id').on('click', function(event){
      event.stopImmediatePropagation()
      $('#edit_employee_photo').click();
    });

    $('#edit_employee_photo').on('change', function(event){
      event.stopImmediatePropagation()
      $('#edit_employee_photo_button').click();
    });

    if (flag) {
      $('#basicSection').addClass("show")
    }

    $("#from_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      startDate: '01/01/1900',
      endDate: new Date().toLocaleDateString('en-GB')
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#to_date').datepicker('setStartDate', null);
        } else if (selected.date != undefined) {
          var minDate = new Date(selected.date.valueOf());
          $('#to_date').datepicker('setStartDate', minDate);
        }
      });

    $("#to_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      startDate: '01/01/1900',
      endDate: new Date().toLocaleDateString('en-GB')
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#from_date').datepicker('setEndDate', new Date().toLocaleDateString('en-GB'));
        } else if (selected.date != undefined) {
          var maxDate = new Date(selected.date.valueOf());
          $('#from_date').datepicker('setEndDate', maxDate);
        }
      });

    var initialFromDate = $('#from_date').datepicker('getDate');
    if (initialFromDate != null){
      $('#to_date').datepicker('setStartDate', initialFromDate);
    }

    var initialToDate = $('#to_date').datepicker('getDate');
    if (initialToDate != null){
      $('#from_date').datepicker('setEndDate', initialToDate);
    }

    $("#filter-from").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      startDate: '01/01/1900',
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#filter-to').datepicker('setStartDate', null);
        } else if (selected.date != undefined) {
          var minDate = new Date(selected.date.valueOf());
          $('#filter-to').datepicker('setStartDate', minDate);
        }
      });

    $("#filter-to").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      startDate: '01/01/1900',
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#filter-from').datepicker('setEndDate', null);
        } else if (selected.date != undefined) {
          var maxDate = new Date(selected.date.valueOf());
          $('#filter-from').datepicker('setEndDate', maxDate);
        }
      });
    $('#filter-from').datepicker('setEndDate', $('#filter-to').datepicker('getDate'));
    $('#filter-to').datepicker('setStartDate', $('#filter-from').datepicker('getDate'));


    $("#wfh_from_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#wfh_to_date').datepicker('setStartDate', null);
        } else if (selected.date != undefined) {
          var minDate = new Date(selected.date.valueOf());
          $('#wfh_to_date').datepicker('setStartDate', minDate);
        }
      });

    $("#wfh_to_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#wfh_from_date').datepicker('setEndDate', null);
        } else if (selected.date != undefined) {
          var maxDate = new Date(selected.date.valueOf());
          $('#wfh_from_date').datepicker('setEndDate', maxDate);
        }
      });
    $('#wfh_to_date').datepicker('setStartDate', $('#wfh_from_date').datepicker('getDate'));
    $('#wfh_from_date').datepicker('setEndDate', $('#wfh_to_date').datepicker('getDate'));


    $("#search-from").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#search-to').datepicker('setStartDate', null);
        } else if (selected.date != undefined) {
          var minDate = new Date(selected.date.valueOf());
          $('#search-to').datepicker('setStartDate', minDate);
        }
      });

    $("#search-to").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#search-from').datepicker('setEndDate', null);
        } else if (selected.date != undefined) {
          var maxDate = new Date(selected.date.valueOf());
          $('#search-from').datepicker('setEndDate', maxDate);
        }
      });
    $('#search-to').datepicker('setStartDate', $('#search-from').datepicker('getDate'));
    $('#search-from').datepicker('setEndDate', $('#search-to').datepicker('getDate'));


    $('#pay_month').datepicker({
      format: "mm/yyyy", // Format to display month and year
      viewMode: "months", // Set initial view mode to months
      minViewMode: "months", // Set minimum view mode to months
      startDate: new Date(),
      autoclose: true
    });

    $(document).on("click", '#mapping-model', function (e) {
      var form = $('#employee-mapping-form')
      var data = JSON.stringify(form.serialize())
      $('#mapping_form_data').val(data)
    });

    $(document).on("change", '.mapping_field', function (e) {
      var form = $('#employee-mapping-form')
      var data = JSON.stringify(form.serialize())
      $('#update_mapping_form_data').val(data)
    });
    $('#working_hour').on('select2:select', function (e) {
      if ($('#working_hour').val() != '') {
        $('#employee_order').val('').trigger('change')
      }
    });

    $('#employee_order').on('select2:select', function (e) {
      if ($('#employee_order').val() != '') {
        $('#working_hour').val('').trigger('change')
      }
    });

    $(document).ready(function() {
      $('#same_as_current').change(function (argument) {
        if (!this.checked) {
          var field_array = ['address', 'city', 'state', 'pincode']
          $.each(field_array, function (index, value) {
            var current_address = ""
            $('#employee_addresses_attributes_1_' + value).val(current_address)
          })
        }else{
          var field_array = ['address', 'city', 'state', 'pincode']
          $.each(field_array, function (index, value) {
            var current_address = $('#employee_addresses_attributes_0_' + value).val()
            $('#employee_addresses_attributes_1_' + value).val(current_address)
          })
        }
      })
    })

    $(document).on("change", '#mappings', function (element) {
      var mapping_value = $("#mappings").val();
      if (mapping_value.length >= 1) {
        $.ajax({
          url: "/organizers/employees/"+mapping_value+"/get_mappings",
          type: "GET",
          success: function(data) {
            $.each( data, function( key, value ) {
              var id = key.replace("[", "_").replace("]","")
              $("#"+id).val(value).trigger('change');
            });
            $("#update_employee_mapping").removeClass('hide')
            $("#update_employee_mapping").addClass('show')
            $("#mapping-model").addClass('hide')
            // if ($("#update_mapping_form_data").length == 0) {
            //   $('#update_employee_mapping').closest("form").append('<input type="hidden" name="mapping_data" id="update_mapping_form_data" />');
            // }
            var form = $('#employee-mapping-form')
            var data = JSON.stringify(form.serialize())
            $('#update_mapping_form_data').val(data)
          }
        });
      }
    });

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl, {"html": true})
    })
  }
}
